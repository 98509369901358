import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const ConfirmationPage = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate('/login');
  };

  return (
    <div className="container">
      <h1>Solicitud Enviada</h1>
      <p>
  <span className="flashing-text">
    IMPORTANTE: SI NO RECIBISTE UNA COPIA DEL FORMULARIO EN EL MAIL COMUNICATE VIA WHATSAPP CON ADMINISTRACION.
  </span>

        <br />
        Su solicitud fue cargada con éxito, espere a que validen los datos en administración y <span className="flashing-text">le responderemos a la brevedad con un NUMERO DE TURNO Y FECHA DE RETIRO.</span>
        <br />
        ¡Muchas gracias!
      </p>
      <button onClick={handleBackToLogin}>Volver al Inicio de Sesión</button>
    </div>
  );
};

export default ConfirmationPage;
